@import '../../variables';
@import '../../styles/mixins/mixins';
@import '../../styles/mixins/flex';
@import "~bootstrap/scss/bootstrap-grid";

#sidebar-menu {
  background: $cnb-dark-blue;

  @include media-breakpoint-down(lg) {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.276834);
    position: sticky;
    top: 0;
    z-index: 1020;
    flex: 0 1 auto;
  }
}

#sidebar-menu .navbar {
  align-items: flex-start;
  margin-top: 16px;
  position: sticky;
  top: 16px;
  margin-left: 15px;

  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }
}

#sidebar-menu .navbar-brand {
  margin-bottom: 12px;
  width: 100%;

  @include media-breakpoint-down(md) {
    width: 50%;

    svg {
      max-height: 52px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-right: 20px;
  }
}

#sidebar-menu .navbar-nav {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-top: 22px;


  @include justify-content(space-evenly);

  &> a {
    padding: 12px 0;
    line-height: 22px;
    color: $cnb-text-white;

    &.active, &:hover {
      color: $cnb-green;
      text-decoration: none;
      svg {
        fill: $cnb-green;
      }
    }

    &.active:hover {
      cursor: default;
    }

    svg {
      margin-right: 8px;
    }
  }
}

#sidebar-menu .navbar-toggler {
  margin-left: auto;
  margin-right: 21px;
  align-self: center;
}
