@import './../../variables.scss';

.table {
   border: 1px solid #dee2e6;
}

.table td {
    vertical-align: middle;
    text-align: center;
    border: none;
    padding: .5rem;
}

tr {
    line-height:30px;
    font-family: $dmr;

    &:hover {
       transition: background-color 100ms linear;
    }
}

th {
    font-family: $pr;
    color: $cnb-title-grey;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: center;
}

thead {
    padding: 20px 0px;
}

.arrow__down--red {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #f00;
    margin-left: 10px;
}

.arrow__down--green {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $cnb-green;
    margin-left: 10px;
}
