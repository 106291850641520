@mixin button-background($background-color) {
    &:active {
        transition: all 0.1s ease;
        transform: scale(1.02);
        background-color: darken($background-color,7.5%);
    }
    &:disabled {
        filter: opacity(70%);
        pointer-events: none;
    }
}

@mixin button-structure($width,$height) {
    width: $width;
    height: $height;
}

@mixin font-structure($font-family,$weight,$size) {
    font-family: $font-family;
    font-weight: $weight;
    font-size: $size;
    line-height: $size * 1.07857;
}
