   @font-face {
    font-family: 'comfortaa-light';
    font-style: normal;
    src: url('../assets/fonts/Comfortaa-Light.ttf');
  }
  
  @font-face {
    font-family: 'comfortaa-bold';
    font-style: normal;
    src: url('../assets/fonts/Comfortaa-Bold.ttf');
  }
  
  @font-face {
    font-family: 'gotham-medium';
    font-style: normal;
    src: url('../assets/fonts/GothamMedium.ttf');
  }
  
  @font-face {
    font-family: 'monserrat-bold';
    font-style: bold;
    src: url('../assets/fonts/Montserrat-Bold.ttf');
  }
  
  @font-face {
    font-family: 'monserrat-regular';
    font-style: normal;
    src: url('../assets/fonts/Montserrat-Regular.ttf');
  }
  
    
  @font-face {
    font-family: 'poppins-light';
    font-style: normal;
    src: url('../assets/fonts/Poppins-Light.ttf');
  }
  
  @font-face {
    font-family: 'poppins-bold';
    font-style: normal;
    src: url('../assets/fonts/Poppins-Bold.ttf');
  }
  
  @font-face {
    font-family: 'poppins-regular';
    font-style: bold;
    src: url('../assets/fonts/Poppins-Regular.ttf');
  }
  
  @font-face {
    font-family: 'poppins-medium';
    font-style: normal;
    src: url('../assets/fonts/Poppins-Medium.ttf');
  }
  
  @font-face {
    font-family: 'poppins-semi-bold';
    font-style: normal;
    src: url('../assets/fonts/Poppins-SemiBold.ttf');
  }
  
  @font-face {
    font-family: 'dm-sans-regular';
    font-style: normal;
    src: url('../assets/fonts/DMSans-Regular.ttf');
  }
  
  
  @font-face {
    font-family: 'dm-sans-bold';
    font-style: normal;
    src: url('../assets/fonts/DMSans-Bold.ttf');
  }
  
  
  