
#c-body {
  stroke-dasharray: 160;
  stroke-dashoffset: 160;
  animation: draw 1s ease-out infinite forwards;
  animation-delay: .2s;
}

#c-arm {
  stroke-dasharray: 40;
  stroke-dashoffset: 40;
  animation: draw 1s ease-out infinite forwards;
}

#c-foot {
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: draw 1s ease-out infinite forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

#cajin-loader .modal-content {
  background: none;
  border: none;
}
