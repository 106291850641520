@import '../../variables.scss';

label, .form-label {
  font-family: $pm;
  color: #3B4346;
  margin-top: 1.5rem;
}

.form-check > label {
  margin: 0 0 0 5px;
  text-align: left;
}

.input-group-text {
  background: #fff;
}

select:disabled, input:disabled {
  cursor: not-allowed;
}

.pill-control {
  label {
    background: #C1C6CE;
    padding: 12px 18px;
    margin: 0 5px;
    font-size: 14px;
    font-family: $dmr;
    color: $cnb-blue;
    font-weight: 600;
  }

  label:hover {
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked ~ label {
    background-color: $cnb-blue;
    color: white;
    transition: background 80ms linear;
  }

  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: not-allowed;
  }
}
