@import '../../variables.scss';

.rs-picker-menu {
  z-index: 5555;
}

.form-group .rs-btn {
  border-color: #ced4da;
  border-radius: .25rem;
}

.invalid-picker .rs-btn {
  border: 1px solid var(--danger);
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $cnb-blue;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: $cnb-green;
  border-color: $cnb-green;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: $cnb-green;
}

.rs-picker-toolbar-option {
  color: $cnb-green;
}

.rs-picker-toolbar-option:hover, .rs-picker-toolbar-option:focus {
  color: #00b386;
}

.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  background-color: #00b386;
}

.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled) {
  background-color: $cnb-green;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: $cnb-blue;
}
