@import './../../variables.scss';

.tower-form__container {
  max-width: 220px !important;

  .card {
    border-radius: .5rem;
    padding: 0;

    .card-header {
      background-color: $cnb-green-success;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      color: white;
      font-family: $dmb;
      font-size: 22px;
      line-height: 1.33rem;
      padding: 1.2rem;

      .btn {
        color: white;
        margin: 0;
        padding: 0;

        .rs-icon {
          margin-right: 0;
        }
      }
    }

    .form-row {
      margin: 0;
    }

    .form-label {
      margin-top: 0;
      margin-bottom: .2rem;
    }

    .custom-radio {
      margin-bottom: 1.2rem;

      label {
        margin-top: 0;
      }
    }
  }
}
