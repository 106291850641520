@import './../../variables.scss';

ul#locker-resume {
  list-style-image: url('../../assets/images/check__done.svg');

  li {
    &:hover {
      background-color: #fafafa;
      transition: background-color linear 100ms;
    }
  }

  h4 {
    color: $cnb-green;
  }

  p {
    color: #979797;
    font-size: 18px;
    padding: .2rem;
  }

  button.edit {
    margin-top: -.2rem;
  }
}

button#register-locker {
  margin-top: 3rem;
  padding: .7rem;
}
