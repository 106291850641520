@import './variables.scss';

/** bootstrap overrides **/
$theme-colors: (
  "primary": $cnb-dark-blue,
  "secondary": $cnb-green,
  "white": $cnb-app-white,
  "danger": $cnb-red-warning,
  "success": $cnb-green-success,
  "info": $cnb-blue-info,
);

@import '~rsuite/dist/styles/rsuite-default.css';
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";
@import './styles/partials/_buttons.scss';
@import './styles/partials/_forms.scss';
@import './styles/partials/_card.scss';
@import './styles/partials/_rsuite.scss';
@import './styles/components/table.scss';
@import './styles/components/modals.scss';
@import './styles/components/pagination.scss';
@import './styles/components/tabs.scss';

html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: white;
  color: $cnb-app-black;
  font-family: $dmr;
  font-size: 14px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6{
  color: $cnb-dark-blue;
  font-family: $psb;
  margin-bottom: 5px;
  font-weight: 600;
}

h1 {
  text-transform: uppercase;
  font-size: 32px;
  line-height: 36px;
  margin-top: 40px;
}

h2 {
  color: $cnb-green;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 2rem;
}

h4 {
  font-family: $dmr;
  font-size: 20px;
  margin-top: 1rem;
}

h4 ~ hr {
  margin-top: .5rem;
}

a {
  font-family: $pr;
}

a:hover {
  transition: color 100ms linear;
}

#wrapper {
  height: 100%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

#main-content {
  background: white;

  @include media-breakpoint-down(md) {
    flex-basis: 0;
    min-height: 0;
  }
}

.empty-state {
  text-align: center;
  color: var(--gray);
  font-size: 16px;
  margin: 14px;
}

.border-gray {
  border: 1px solid transparentize($cnb-dark-blue, .5);
  border-radius: .5rem;
}

.badge-pill {
  user-select: none;
  margin-left: .25rem;
}

.badge-attention-1 {
  background-color: $cnb-attention;
  color: $cnb-text-white;
}

.badge-attention-2 {
  background-color: lighten($cnb-attention, 15);
  color: $cnb-text-white;
}

.alert > button, .alert > p {
  margin: 0 !important;
}

.alert-pop {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
}

.spinner--full {
  width: 4rem;
  height: 4rem;
  border-width: .35rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  @include align-self(center);
}

.fade.in, .fade.show:not(.modal-backdrop) {
  opacity: 1 !important;
  pointer-events: auto;
}

.dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: .5rem;
}

.bg-gray {
  background-color: $cnb-grey;
}
