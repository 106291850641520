@import './../../variables.scss';
@import './../../styles/mixins/flex.scss';

.tower-set-container {
  align-items: flex-end;

  .col {
    max-width: 180px !important;
  }

  .tower-resume {
    font-family: $pb;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding-top: 8px;
  }
}

.row.tower-frame {
  margin-right: 8px;
  margin-left: 8px;
  padding: 4px 8px;
  flex-direction: column;

  .tower-box {
    cursor: auto;
  }
}

.color-block {
  border: 2px solid $cnb-blue;
  border-radius: 0.5rem;
  width: 22px;
  height: 22px;
  display: inline-block;

  &.bg-primary-complementary {
    background-color: #8cc54e;
  }

  &.bg-secondary-complementary {
    background-color: #c5884e;
  }
}

.tower-drop-container,
.tower-frame,
.tower-box {
  border: 2px solid $cnb-blue;
  border-radius: 0.5rem;
}

.tower-drop-container {
  padding: 4px 8px;
  height: 670px;
  overflow: hidden;
  @include align-content(start);
}

.tower-box {
  margin: 4px 0;
  user-select: none;
  cursor: grab;
  font-family: $pb;
  font-size: 18px;
  text-align: center;
  background-color: white;

  &.dragging {
    border-width: 0;
    font-size: 0;
    background-color: $cnb-light-grey;
  }

  &.is-main {
    background-color: transparentize($cnb-blue, 0.6) !important;
  }

  &:not(.is-main).box-unavailable {
    background-color: $cnb-light-grey;
  }

  &.bg-primary-complementary:not(.is-main) {
    background-color: #8cc54e;
  }

  &.bg-secondary-complementary:not(.is-main) {
    background-color: #c5884e;
  }
}

.box-s {
  height: 39px;
  line-height: 35px;
}

.box-m {
  height: 86px;
  line-height: 80px;
}

.box-l {
  height: 133px;
  line-height: 128px;
}

.box-xl {
  height: 180px;
  line-height: 174px;
}
