@import '../../variables.scss';
@import '../mixins/mixins.scss';

button, .btn {
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .5px;
    outline: none;
    margin: 6px auto;
    font-family: $psb;
}

.button--has--shadow{
    -webkit-box-shadow: 0px 5px 16px -5px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 5px 16px -5px rgba(0,0,0,0.4);
    box-shadow: 0px 5px 16px -5px rgba(0,0,0,0.4);
}

.button--icon {
    @include button-structure(40px, 40px);
    box-shadow: none;
    border-radius: 10px;
    img {
        margin: 0px;
    }
}

.button--transparent {
    @include button-background(transparent);
}

.Datepicker {
    width: 199px;
    height: 45px;
    background-color: white;
    @extend %flex-row;
    position: relative;
    img {
        position: absolute;
        z-index: 2;
        left:  10;
        top: 0;
    }
}

.btn:hover:not(.btn-link-secondary):not(:disabled){
    transform: scale(1.01);
}

.btn-link-secondary:focus, .btn-link-secondary.focus {
    box-shadow: none;
}

.btn-link:hover, .btn-link-secondary:hover {
    font-size: 1.1rem;
    transition: font-size 100ms linear;
}

.btn-link, .btn-link-secondary {
    line-height: 1.1rem;
}

.input-group > .input-group-append > .btn {
    margin: 0;
}

button .svg-secondary {
    fill: $cnb-green;
    width: 22px;
}

button:hover .svg-secondary {
    fill: $white;
}

button:not(.btn-block) > .spinner-border:not(.spinner--full) {
    height: 20px;
    width: 20px;
    margin-right: 7px;
}

button > .rs-icon {
    margin-right: 7px;
}

.btn-lg {
    padding-right: 3rem;
    padding-left: 3rem;
}
