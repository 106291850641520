@import './styles/fonts.scss';

/* Colors */
$cnb-green: #00CC99;
$cnb-blue: #1E3E5B;
$cnb-grey: #9B9B9B;
$cnb-light-grey: #EBEBEB;
$cnb-darker-grey: #757575;
$cnb-bg-transparent: rgba(0,0,0,0.70);
$cnb-green-success: #00BB99;
$cnb-red-warning: #F92A2A;
$cnb-app-white: #FFFFFF;
$cnb-app-black: #212529;
$cnb-content-grey: #2C3E50;
$cnb-title-grey: #979797;
$cnb-dark-blue: #2F415A;
$cnb-text-white: #EAEAEA;
$cnb-attention: #3D405B;
$cnb-blue-info: #00A9E0;

$el-padding-s: 2.5%;
$el-padding-m: 5%;
$el-padding-l: 10%;

/* Fonts */
$mr: 'monserrat-regular';
$ml: 'monserrat-light';
$mb: 'monserrat-bold';
$cl: 'comfortaa-light';
$cb: 'comfortaa-bold';
$gm: 'gotham-medium';
$pl: 'poppins-light';
$pb: 'poppins-bold';
$pr: 'poppins-regular';
$pm: 'poppins-medium';
$psb: 'poppins-semi-bold';
$dmr: 'dm-sans-regular';
$dmb: 'dm-sans-bold';

%flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

%flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

%flex-column-sb-c{
    justify-content: space-between;
    align-items: center;
}

%flex-row-ua {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

%flex-center {
    align-items: center;
    align-content: center;
    justify-content: center;
}

