.codes--value {
	font-size: 16px;
	margin-bottom: .5rem;

	b {
		font-size: 18px;
	}
}

.codes--qr {
	width: 100%;
	max-width: 160px;
	margin-bottom: 1rem;
}
