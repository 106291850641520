@import 'src/variables.scss';

.Login__container {
    min-height: 520px;
    height: 100%;
    background: #fafafa;
    @extend %flex-column;
    @extend %flex-center;

    img {
        width: 88px;
        margin-bottom: 2rem;
    }
}

.Login__form {
    width: 80%;
    padding: 30px;
    position: relative;
    max-width: 426px;

    button {
        margin-top: 5rem;
    }
}
