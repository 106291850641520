.tower-form__row {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  .tower-form__add-button {
    padding: 32px 16px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0px 5px 12px rgba(147, 155, 161, 0.35);

    i.rs-icon {
      margin-right: 0;
    }
  }

  .tower-form__towers {
    overflow-x: auto;
    flex-wrap: nowrap !important;
    min-height: 798px;
    margin-bottom: 2px;
  }
}
